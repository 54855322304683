//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

import GobblerIcon from '@/components/icons/GobblerIcon';
import Loader from '@/components/main/Loader';
import api from "@/api/api";
import notification from '@/notification/notification';

export default {
  name: "CertificateItems",
  components: {
    GobblerIcon,
    Loader
  },
  props: {
    certificates: Array,
    selectedCertificate: Object,
    setSelectedCertificate: Function,
    getError: String,
    getFormattedDate: Function,
    canBeSelected: Function,
    isActive: Function,
    signPep: Function,
    allowPep: Boolean,
    allowGKey: Boolean,
    documentId: String,
    hide: Function,
  },
  data() {
    return {
      isLoader: false,
      tooltipVisible: false,
      activeDateTooltipVisible: false,
      gKeyPending: false,
    }
  },
  methods: {
    async setCertificate(certificate) {
      if(!this.canBeSelected(certificate) || !this.isActive(certificate)) {
        return;
      }
      if (!this.isSelected(certificate)) {
        this.setSelectedCertificate(certificate)
      } else {
        this.setSelectedCertificate(null)
      }
    },
    isSelected(certificate) {
      return this.selectedCertificate && (certificate.SerialNumber === this.selectedCertificate.SerialNumber)
    },
    isPersonalCertificate(certificate) {
      return certificate.type === 'personal'
    },
    getCertTypeName(certificate) {
      return this.isPersonalCertificate(certificate) ? this.$t('certificates_modal.nep_type') : this.$t('certificates_modal.kep_type')
    },
    async handleSendToGKey() {
      if (!this.documentId || this.gKeyPending || !this.worker.snils.length || !this.hasAccessToSignGkey || !this.allowGKey) return;
      this.gKeyPending = true;
      let gKeyResponse = null;
      try {
        gKeyResponse = await api.sendDocToGKey({ docId: this.documendatatId });
        // gKeyResponse = { data:{ error: false, result: { status: 'testing'}}};
        if (!Number.isInteger(gKeyResponse.data.error)) {
          console.log("Successfully signed!");
          notification.information(gKeyResponse.data.result.status);
          this.hide(true);
        }
      } catch(error) {
        console.error(error);
      } finally {
        this.gKeyPending = false;
      }
    },
  },
  computed: {
    ...mapState(['currentStudent', 'worker', 'hasAccessToSignGkey', 'isSnilsEditable'])
  }
}
